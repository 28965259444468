<template>
  <div class="relative text-dark px-2" >

    <div class="flex items-center gap-2" @click="opened = true">
      <i class="fal fa-language" ></i>

      <div class="uppercase">{{locale}}</div>
    </div>


    <OnClickOutside @trigger="opened=false">
    <Transition name="dropdown">
      <div v-if="opened" class="absolute flex flex-col top-6 left-0 bg-white rounded border  drop-shadow divide-y">
        <template v-for="l in availableLocales">
          <div class="py-1 px-2 hover:bg-light cursor-pointer uppercase" @click="changeLanguage(l)">
            {{l}}
          </div>
        </template>
      </div>
    </Transition>
    </OnClickOutside>
  </div>
</template>
<script lang="ts" setup>
import { OnClickOutside } from "@vueuse/components";
import {useI18n} from "vue-i18n";

const opened = ref(false)

const { t, availableLocales, locale } = useI18n()

const changeLanguage = (l:string) => {
  locale.value  = l;
  localStorage.setItem('lang', l);
  opened.value = false
}
</script>
